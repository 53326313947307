<template>
  <div class="page">
    <HeaderTitle />
    <div class="d-flex justify-content-end">
      <router-link to="/car-driver" class="btn btn-secondary mr-2">Отменить</router-link>
      <button v-if="carDriver" class="btn btn-primary mr-2" @click="updateRecord">Сохранить</button>
      <button v-if="carDriver" class="btn btn-danger" @click="deleteRecord">Удалить</button>
    </div>
    <div v-if="carDriver">
      <div class="row">
        <div class="col-12">
          <label>Регистрационный номер машины</label>
          <VSelect
            v-model="carDriver.car_id"
            style="width: 100%"
            :options="cars"
            :reduce="option => option.id"
            label="registration_plate"
          >
            <template v-slot:option="option">
              {{ option.brand }} {{ option.model }}
              <sub>рег. номер: {{ option.registration_plate }}</sub>
            </template>
          </VSelect>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Водитель</label>
          <VSelect
            v-model="carDriver.driver_id"
            style="width: 100%"
            :options="drivers"
            :reduce="option => option.id"
            label="last_name"
          >
            <template v-slot:option="option">{{ option.last_name }} {{ option.first_name }} {{ option.middle_name }}</template>
          </VSelect>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Дата передачи</label>
          <input
            v-model="transferDatetime"
            type="date"
            class="form-control"
            min="2010-06-01T08:30"
            max="2050-06-30T16:30"
            required
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Дата возврата</label>
          <input
            v-model="receivingDatetime"
            type="date"
            class="form-control"
            min="2010-06-01T08:30"
            max="2050-06-30T16:30"
            required
          >
        </div>
      </div>
      <hr class="mb-4">
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapState, mapActions } from 'vuex'
import CarDriverApi from '../../api/carDriver'
import dayjs from 'dayjs'

export default {
  name: 'UpdateCarDriver',
  props: {
    id: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      isUpdating: false,
      carDriver: null,
    }
  },
  computed: {
    ...mapState('fuel/carDriver', {
      carDriverState (state) {
        return state.items.find(e => e.id == this.id)
      },
      isLoading: state => state.isLoading
    }),
    ...mapState('taxi/cars', {
      cars: state => state.items
    }),
    ...mapState('taxi/drivers', {
      drivers: state => state.items
    }),
    receivingDatetime: {
      get () {
        return dayjs(this.carDriver.receiving_datetime).format('YYYY-MM-DD')
      },
      set (dateTime) {
        this.carDriver.receiving_datetime = dayjs(dateTime, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ssZZ')
      }
    },
    transferDatetime: {
      get () {
        return dayjs(this.carDriver.transfer_datetime).format('YYYY-MM-DD')
      },
      set (dateTime) {
        this.carDriver.transfer_datetime = dayjs(dateTime, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ssZZ')
      }
    },
  },
  beforeMount () {
    this.loadCars()
    this.loadDrivers()
    if (!this.carDriverState) {
      return this.loadCarDriver()
        .then(() => {
          this.carDriver = JSON.parse(JSON.stringify(this.carDriverState ? this.carDriverState : null))
        })
    }
    this.carDriver = JSON.parse(JSON.stringify(this.carDriverState))
  },
  mounted () {
    this.setTitle([
      {
        title: 'Текущий водитель',
        url: '/car-driver'
      }, {
        title: 'Изменить'
      }
    ])
  },
  methods: {
    deleteRecord () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите отвязать водителя от машины?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Отвязать'
      }).then(event => {
        if (event.value) {
          CarDriverApi.archivateItem(this.carDriver.id)
            .then(() => {
              this.$router.push('/car-driver')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    updateRecord () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      CarDriverApi.updateItem(this.carDriver)
        .then(() => {
          this.isUpdating = false
          this.$router.push('/car-driver')
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi/cars', {
      loadCars: 'loadItems'
    }),
    ...mapActions('fuel/carDriver', {
      loadCarDriver: 'loadItems'
    }),
    ...mapActions('taxi/drivers', {
      loadDrivers: 'loadItems'
    }),
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
