<template>
  <div class="page">
    <div class="d-flex justify-content-end mb-2">
      <router-link to="/car-driver/add" class="btn btn-primary">
        <font-awesome-icon class="text-white" icon="plus" />
        Добавить
      </router-link>
    </div>
    <table class="table table-hover mt-3 d-none d-md-table">
      <TableSort class="sticky-1" :sorts="sorts" @sortsUpdate="loadWithSort" />
      <tbody>
        <tr v-for="carDriver of carDriverList" :key="carDriver.id" @click="$router.push(`/car-driver/update/${carDriver.id}`)">
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ carDriver.car.brand }} {{ carDriver.car.model }} - {{ carDriver.car.registration_plate }}</TextHighlight>
          </td>
          <td>
            <TextHighlight :queries="searchText">
              {{ carDriver.driver.first_name }}
              {{ carDriver.driver.last_name }}
              {{ carDriver.driver.middle_name }}
            </TextHighlight>
          </td>
          <td>
            <TextHighlight :queries="searchText">{{ carDriver.transfer_datetime | formatDate }}</TextHighlight>
          </td>
          <td>
            <TextHighlight v-if="carDriver.receiving_datetime" :queries="searchText">{{ carDriver.receiving_datetime | formatDate }}</TextHighlight>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-for="carDriver of carDriverList" :key="carDriver.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
      <table v-if="carDriverList && carDriverList.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td class="border-top-0">Автомобиль</td>
            <td class="border-top-0">
              <TextHighlight :queries="searchText">
                {{ carDriver.car.brand }}
                {{ carDriver.car.model }} -
                {{ carDriver.car.registration_plate }}
              </TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Водитель</td>
            <td>
              <TextHighlight :queries="searchText">
                {{ carDriver.driver.first_name }}
                {{ carDriver.driver.last_name }}
                {{ carDriver.driver.middle_name }}
              </TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Дата передачи</td>
            <td>
              <TextHighlight :queries="searchText">{{ carDriver.transfer_datetime | formatDate }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Дата возврата</td>
            <td>
              <TextHighlight v-if="carDriver.receiving_datetime" :queries="searchText">{{ carDriver.receiving_datetime | formatDate }}</TextHighlight>
            </td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="`/car-driver/update/${ carDriver.id }`"
        class="btn btn-primary btn-md create-car ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'CarDriver',
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'Автомобиль',
          storeName: 'carIndex',
          values: ['', 'car_id', '-car_id'],
          order: 1,
          disabled: true
        }, {
          name: 'Водитель',
          storeName: 'statusIndex',
          values: ['', 'driver_id', '-driver_id'],
          order: 2,
          disabled: true
        }, {
          name: 'Дата передачи',
          storeName: 'transferDatetimeIndex',
          values: ['', 'transfer_datetime', '-transfer_datetime'],
          order: 0
        }, {
          name: 'Дата возврата',
          storeName: 'receivingDatetimeIndex',
          values: ['', 'receiving_datetime', '-receiving_datetime'],
          order: 0
        },
      ]
    }
  },
  computed: {
    ...mapState('fuel/carDriver', {
      carDriverList: state => state.items.filter(item => !item.archived_at),
      searchText: state => state.searchText,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading
    }),
  },
  watch: {
    page () {
      this.loadItems({ page: Number(this.page) })
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'Текущий водитель'
      }
    ])
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('fuel/carDriver', [
      'abortLoading',
      'setSearch',
      'loadItems',
      'setSearchText',
      'setSorts'
    ]),
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page) })
    },
  }
}
</script>

<style scoped>
  @media screen and (max-width: 1199px) {
    .page {
      padding: 1rem;
    }
  }
  @media screen and (max-width: 576px) {
    .page {
      padding: 0.5rem;
    }
  }
</style>
