/* eslint-disable camelcase */
import APITemplate from '@/api/APITemplate'
const headers = {
  accept: 'application/json',
  'content-type': 'application/json'
}

export default {
  ...APITemplate('/api/car-fueling'),
  getItemsWith (expand) {
    const controller = new AbortController()
    return {
      promise: fetch('/api/car-fueling?expand=' + expand.toString(), {
        method: 'GET',
        credentials: 'include',
        redirect: 'error',
        headers
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Ошибка при загрузке.')
          }
          return new Promise((resolve, reject) => {
            response.json().then(items => {
              resolve({
                items
              })
            })
          })
        }),
      abort () {
        controller.abort()
      }
    }
  },
  importFueling (formData) {
    return fetch('/api/car-fueling/import-fueling', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      body: formData
    }).then(response => response.json())
  }
}
