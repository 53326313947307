<template>
  <div class="page">
    <HeaderTitle />
    <div class="d-flex justify-content-end">
      <router-link to="/fuel-types" class="btn btn-secondary mr-2">Отменить</router-link>
      <button class="btn btn-primary" @click="updateFuelType">Сохранить</button>
    </div>
    <div v-if="fuelType" class="row">
      <div class="col-12">
        <label>Название</label>
        <input
          v-model="fuelType.name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <hr class="mb-4">
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapState, mapActions } from 'vuex'
import FuelTypeApi from '../../api/fuelType'

export default {
  name: 'UpdateFuelType',
  props: {
    id: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      isUpdating: false
    }
  },
  computed: {
    ...mapState('fuel/fuelType', {
      fuelState (state) {
        return state.items.find(e => e.id == this.id)
      },
      isLoading: state => state.isLoading
    }),
    fuelType: {
      get () {
        if (!this.fuel) {
          return JSON.parse(JSON.stringify(this.fuelState ? this.fuelState : null))
        }
        return this.fuel
      },
      set (value) {
        this.fuel = value
      }
    }
  },
  mounted () {
    this.loadItem({ id: this.id })
    this.setTitle([
      {
        title: 'Топливо',
        url: '/fueling'
      }, {
        title: 'Виды топлива',
        url: '/fuel-types'
      }, {
        title: 'Изменить'
      }
    ])
  },
  methods: {
    updateFuelType () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      FuelTypeApi.updateItem(this.fuelType)
        .then(() => {
          this.isUpdating = false
          this.$router.push('/fuel-types')
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('fuel/fuelType', ['loadItem']),
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
