/* eslint-disable camelcase */
import APITemplate from '@/api/APITemplate'
const headers = {
  accept: 'application/json',
  'content-type': 'application/json'
}
const url = '/api/fuel-consumption'

export default {
  ...APITemplate(url),
  archiveItem (id) {
    return fetch(`${url}/archive?id=${id}`, {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers
    }).then(response => response.json())
  },
  getActualConsumptions () {
    return fetch(`${url}/get-actual-consumptions`, {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers
    }).then(response => response.json())
  },
  getItemsWith (expand) {
    const controller = new AbortController()
    return {
      promise: fetch(`${url}?expand=` + expand.toString(), {
        method: 'GET',
        credentials: 'include',
        redirect: 'error',
        headers
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Ошибка при загрузке.')
          }
          return new Promise((resolve, reject) => {
            response.json().then(items => {
              resolve({
                items
              })
            })
          })
        }),
      abort () {
        controller.abort()
      }
    }
  }
}
