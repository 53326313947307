var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-fuel-type"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":"/fuel-types/create"}},[_c('font-awesome-icon',{staticClass:"text-white",attrs:{"icon":"plus"}}),_vm._v(" Добавить ")],1)],1),_c('br'),_c('div',{staticClass:"draggable-cards-wrapper"},[_c('DraggableTree',{staticClass:"tree4",attrs:{"data":_vm.fuelTypes,"draggable":true,"indent":30,"space":0},on:{"change":_vm.changeItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _c('div',{},[(!data.isDragPlaceHolder)?[_c('div',{staticClass:"row text-dark py-3 list-group-item"},[_c('div',{staticClass:"col"},[_c('font-awesome-icon',{staticClass:"mx-2",style:({
                  color: 'gray',
                  'margin-left': 'auto',
                  cursor: 'pointer'
                }),attrs:{"icon":"equals"},on:{"click":function($event){return _vm.store.toggleOpen(data)}}}),_vm._v(" "+_vm._s(data.name)+" "),_c('span',{staticClass:"badge",staticStyle:{"line-height":"unset"}},[_vm._v(" "+_vm._s(data.archived_at)+" ")])],1),_c('div',{staticClass:"col-auto right-icons"},[_c('font-awesome-icon',{style:({
                  color: 'gray',
                  'margin-left': 'auto',
                  cursor: 'pointer'
                }),attrs:{"icon":"pen"},on:{"click":function($event){return _vm.$router.push(("/fuel-types/update/" + (data.id)))}}}),_c('font-awesome-icon',{style:({
                  color: 'gray',
                  'margin-left': '1.5rem',
                  cursor: 'pointer'
                }),attrs:{"icon":"trash-alt"},on:{"click":function($event){return _vm.removeItem(data)}}})],1)])]:_vm._e()],2)}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }