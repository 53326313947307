<template>
  <div class="page">
    <div v-if="!editMode" class="float-right">
      <button class="btn btn-primary mr-2" @click="editModeEnable">
        <font-awesome-icon class="text-white" icon="plus" />
        Добавить
      </button>
      <router-link to="/fuel-consumptions" class="btn btn-secondary">Назад</router-link>
    </div>
    <button v-else class="btn float-right btn-primary" @click="addConsumption">Добавить топливо</button>
    <h3>Добавление расхода</h3>
    <div v-if="!editMode">
      <table class="table table-hover w-100 mt-3 d-none d-md-table">
        <TableSort class="sticky-1" :sorts="sorts" @sortsUpdate="loadWithSort" />
        <tbody>
          <tr v-if="getConsumptionsCar.length === 0">
            <td colspan="6" style="text-align: center">Ничего не найдено</td>
          </tr>
          <tr v-for="(consumptionItem, key) of getConsumptionsCar" :key="key">
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ getFuelType(consumptionItem.fuel_type_id) ? getFuelType(consumptionItem.fuel_type_id).name : null }}</TextHighlight>
            </td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ consumptionItem.date | formatDateTime }}</TextHighlight>
            </td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ consumptionItem.author.first_name }} {{ consumptionItem.author.last_name }} {{ consumptionItem.author.middle_name }}</TextHighlight>
            </td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ consumptionItem.consumption }}</TextHighlight>
            </td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ consumptionItem.created_at | formatDateTime }}</TextHighlight>
            </td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ consumptionItem.percentage_of_use }} %</TextHighlight>
            </td>
            <td>
              <font-awesome-icon
                icon="trash-alt"
                :style="{
                  color: 'gray',
                  'margin-left': '1.5rem',
                  cursor: 'pointer'
                }"
                @click="removeConsumptionOld(consumptionItem.id)"
              />
              <router-link
                :to="'/fuel-consumptions/update/' + consumptionItem.id"
              >
                <font-awesome-icon
                  icon="pen"
                  :style="{
                    color: 'gray',
                    'margin-left': '1.5rem',
                    cursor: 'pointer'
                  }"
                />
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-for="(consumptionItem, key) of getConsumptionsCar" :key="key" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
        <table v-if="getConsumptionsCar && getConsumptionsCar.length > 0" class="table borderless">
          <tbody>
            <tr>
              <td style="border-top: none">Автомобиль</td>
              <td style="border-top: none">
                <TextHighlight class="highlighter" :queries="searchText">{{ consumptionItem.car.brand }} {{ consumptionItem.car.model }} - {{ consumptionItem.car.registration_plate }}</TextHighlight>
              </td>
            </tr>
            <tr>
              <td>Дата</td>
              <td>
                <TextHighlight class="highlighter" :queries="searchText">{{ consumptionItem.date | formatDateTime }}</TextHighlight>
              </td>
            </tr>
            <tr>
              <td>Тип топлива</td>
              <td>
                <TextHighlight class="highlighter" :queries="searchText">{{ getFuelType(consumptionItem.fuel_type_id) ? getFuelType(consumptionItem.fuel_type_id).name : null }}</TextHighlight>
              </td>
            </tr>
            <tr>
              <td>Расход на 100км</td>
              <td>
                <TextHighlight class="highlighter" :queries="searchText">{{ consumptionItem.consumption }}</TextHighlight>
              </td>
            </tr>
            <tr>
              <td>Автор</td>
              <td>
                <TextHighlight class="highlighter" :queries="searchText">{{ consumptionItem.author.first_name }} {{ consumptionItem.author.last_name }} {{ consumptionItem.author.middle_name }}</TextHighlight>
              </td>
            </tr>
            <tr>
              <td>Дата внесения записи</td>
              <td>
                <TextHighlight class="highlighter" :queries="searchText">{{ consumptionItem.created_at | formatDateTime }}</TextHighlight>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex">
          <router-link
            :to="{ name: 'carPage', params: { consumptionId: consumptionItem.id } }"
            class="btn btn-danger btn-md ml-auto"
            @click="removeConsumptionOld(consumptionItem.id)"
          >
            Удалить
          </router-link>
          <router-link
            class="btn btn-primary btn-md mx-2"
            :to="'/fuel-consumptions/update/' + consumptionItem.id"
          >
            Редактировать
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="editMode">
      <div class="row">
        <div class="col-12 col-lg-4">
          Дата начала
        </div>
        <div class="col-12 col-lg-4">
          <input
            v-model="dateStartConsumption"
            type="date"
            class="form-control"
            placeholder
            required
          >
        </div>
      </div>
      <table class="table w-100 mt-3 d-none d-md-table">
        <thead>
          <tr>
            <th>Топливо</th>
            <th>Расход на 100км</th>
            <th>Процент использования</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="consumptionsCarCopy.length === 0">
            <td colspan="4" style="text-align: center">Ничего не найдено</td>
          </tr>
          <tr v-for="(consumptionItem, key) of consumptionsCarCopy" :key="key">
            <td>
              <VSelect
                v-model="consumptionItem.fuel_type_id"
                style="width: 100%"
                :options="fuelTypesCopy"
                :reduce="option => option.id"
                label="name"
              >
                <template v-slot:option="option">{{ option.name }}</template>
              </VSelect>
            </td>
            <td>
              <input
                v-model="consumptionItem.consumption"
                type="number"
                class="form-control"
                placeholder
                required
              >
            </td>
            <td class="row w-100">
              <input
                v-model="consumptionItem.percentage_of_use"
                class="for col p-0"
                style="max-width: 200px"
                type="range"
                min="0"
                max="100"
                step="1"
              >
              <input
                v-model="consumptionItem.percentage_of_use"
                class="col-auto ml-2 p-0"
                style="width: 50px;"
                type="number"
              >
            </td>
            <td>
              <font-awesome-icon
                icon="trash-alt"
                :style="{
                  color: 'gray',
                  'margin-left': '1.5rem',
                  cursor: 'pointer'
                }"
                @click="deleteConsumption(key)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-for="(consumptionItem, key) of consumptionsCarCopy" :key="key" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
        <table v-if="consumptionsCarCopy && consumptionsCarCopy.length > 0" class="table borderless">
          <tbody>
            <tr>
              <td>Топливо</td>
              <td>
                <VSelect
                  v-model="consumptionItem.fuel_type_id"
                  style="width: 100%"
                  :options="fuelTypesCopy"
                  :reduce="option => option.id"
                  label="name"
                >
                  <template v-slot:option="option">{{ option.name }}</template>
                </VSelect>
              </td>
            </tr>
            <tr>
              <td>Расход на 100км</td>
              <td>
                <input
                  v-model="consumptionItem.consumption"
                  type="number"
                  class="form-control"
                  placeholder
                  required
                >
              </td>
            </tr>
            <tr>
              <td>Процент использования</td>
              <td>
                <input
                  v-model="consumptionItem.percentage_of_use"
                  class="for col p-0"
                  style="max-width: 200px"
                  type="range"
                  min="0"
                  max="100"
                  step="1"
                >
                <input
                  v-model="consumptionItem.percentage_of_use"
                  class="col-auto ml-2 p-0"
                  style="width: 50px;"
                  type="number"
                >
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex">
          <button
            v-if="consumptionItem.date_end"
            :to="{ name: 'carPage', params: { consumptionId: consumptionItem.id } }"
            class="btn btn-danger btn-md ml-auto"
            @click="deleteConsumption(consumptionItem.id)"
          >
            Удалить
          </button>
        </div>
      </div>
      <button class="btn btn-primary mr-2" @click="saveConsumption">Добавить</button>
      <button class="btn btn-secondary" @click="editMode = false">Отменить</button>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { mapState, mapActions } from 'vuex'
import userApi from '@/api/user'
import FuelConsumptionsApi from '../../api/fuelConsumptions'

export default {
  name: 'ConsumptionCar',
  props: {
    id: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      editMode: false,
      consumptionsCarCopy: [],
      sorts: [
        {
          name: 'Тип топлива',
          storeName: 'fuelTypeIdIndex',
          values: ['', 'fuel_type_id', '-fuel_type_id'],
          order: 2
        }, {
          name: 'Дата',
          storeName: 'dateIndex',
          values: ['', 'date', '-date'],
          order: 0
        }, {
          name: 'Автор',
          storeName: 'authorIdIndex',
          values: ['', 'author_id', '-author_id'],
          order: 1,
          disabled: true
        }, {
          name: 'Расход на 100км',
          storeName: 'consumptionIndex',
          values: ['', 'consumption', '-consumption'],
          order: 3
        }, {
          name: 'Дата внесения записи',
          storeName: 'createdAtIndex',
          values: ['', 'created_at', '-created_at'],
          order: 4
        }, {
          name: 'Процент использования',
          storeName: 'percentageOfUseIndex',
          values: ['', 'percentage_of_use', '-percentage_of_use'],
          order: 5,
          disabled: true
        }, {
          name: ' ',
          storeName: 'percentageOfUseIndex',
          values: ['', 'percentage_of_use', '-percentage_of_use'],
          order: 6,
          disabled: true
        }
      ],
      user: null,
      fuelTypesCopy: null,
      date: null
    }
  },
  computed: {
    ...mapState('fuel/fuelConsumption', {
      consumptionList: state => state.items,
      searchText: state => state.searchText,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading
    }),
    ...mapState('taxi/cars', {
      cars: state => state.items
    }),
    ...mapState('fuel/fuelType', {
      fuelTypes: state => state.items.filter(e => !e.archived_at)
    }),
    getFuelType () {
      return (fuelTypeId) => {
        if (!this.fuelTypesCopy) {
          return null
        }
        return this.fuelTypesCopy.find(fuelType => fuelType.id === fuelTypeId)
      }
    },
    dateStartConsumption: {
      get () {
        return dayjs(this.date, 'YYYY-MM-DDTHH:mm:ssZZ').format('YYYY-MM-DD')
      },
      set (dateTime) {
        this.date = dayjs(dateTime, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ssZZ')
      }
    },
    getConsumptionsCar () {
      return this.consumptionList.filter(e => e.car.id == this.id)
    }
  },
  watch: {
    id: {
      handler (carId) {
        const car = this.cars.find(car => car.id == carId)
        if (!car) {
          return false
        }
        this.setTitle([
          {
            title: 'Расход',
            url: '/fuel-consumptions'
          },
          {
            title: 'Изменить'
          },
          {
            title: car.brand + ' ' + car.model + ' ' + car.registration_plate
          }
        ])
      },
      immediate: true
    }
  },
  beforeMount () {
    this.loadCars()
    this.loadFuels().then(fuels => {
      this.fuelTypesCopy = JSON.parse(JSON.stringify(fuels))
    })
    userApi.getCurrentUser()
      .then(user => {
        this.user = user
      })
      .catch(err => {
        console.error(err)
        this.isCreating = false
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: err.message
        })
      })
  },
  methods: {
    ...mapActions('taxi/cars', {
      loadCars: 'loadItems'
    }),
    ...mapActions('fuel/fuelType', {
      loadFuels: 'loadItems'
    }),
    ...mapActions('fuel/fuelConsumption', { loadConsumption: 'loadItems', setSorts: 'setSorts' }),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadConsumption()
    },
    editModeEnable () {
      this.editMode = true
      this.date = this.getConsumptionsCar.length ? this.getConsumptionsCar[0].date : null
      const usedFuelType = []
      this.consumptionsCarCopy = JSON.parse(JSON.stringify(
        this.getConsumptionsCar
          .filter(consumption => {
            if (!consumption.date_end && !usedFuelType.includes(consumption?.fuel_type_id)) {
              usedFuelType.push(consumption.fuel_type_id)
              return true
            }
            return false
          })
      ))
    },
    addConsumption () {
      this.consumptionsCarCopy.push({
        fuel_type_id: 0,
        consumption: 0,
        date: 0,
        percentage_of_use: 0,
        create_at: 0
      })
    },
    saveConsumption () {
      const usedFuelTypes = []
      let incorrectDataEntered = false
      this.consumptionsCarCopy.forEach(consumption => {
        if (usedFuelTypes.includes(consumption.fuel_type_id)) {
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: 'Присутствуют повторяющиеся виды топлива'
          })
          incorrectDataEntered = true
          return false
        }
        usedFuelTypes.push(consumption.fuel_type_id)
      })
      if (!incorrectDataEntered) {
        const allPercentage = this.consumptionsCarCopy.reduce(function (prev, consumption) {
          return prev + Number(consumption.percentage_of_use)
        }, 0)
        if (allPercentage === 100) {
          this.consumptionsCarCopy.forEach(consumption => {
            if (dayjs(this.date, 'YYYY-MM-DDTHH:mm:ssZZ')) {
              consumption.date = this.date
              consumption.author_id = this.user.id
              consumption.car_id = this.id
              FuelConsumptionsApi.createItem(consumption)
                .catch(err => {
                  console.error(err)
                  Swal.fire({
                    icon: 'error',
                    title: 'Ошибка',
                    html: err.message
                  })
                })
              this.editMode = false
            }
            else {
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: 'Дата введена неверно!'
              })
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: 'Общее число процентов не равно 100'
          })
        }
      }
    },
    deleteConsumption (key) {
      this.$delete(this.consumptionsCarCopy, key)
    },
    removeConsumptionOld (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить расход?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          FuelConsumptionsApi.removeItem(id)
            .then(() => {
              this.loadConsumption()
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    }
  }
}
</script>
