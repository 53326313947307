import Fueling from './views/Fueling'
import FuelTypes from './views/FuelTypes'
import CreateFuelType from './views/fuelType/Create'
import UpdateFuelType from './views/fuelType/Update'
import CreateFuelingType from './views/fueling/Create'
import UpdateFuelingType from './views/fueling/Update'
import Consumption from './views/FuelConsumption'
import CreateConsumptionType from './views/consumption/Create'
import UpdateConsumptionType from './views/consumption/Update'
import ConsumptionCar from './views/consumption/ConsumptionCar'
import CarDriver from './views/CarDriver'
import UpdateCarDriver from './views/carDriver/Update'
import CreateCarDriver from './views/carDriver/Create'

export default [
  {
    path: '/fueling',
    component: Fueling,
    meta: {
      title: 'Заправки',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/fueling/create',
    component: CreateFuelingType,
    meta: {
      title: 'Добавить заправку',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/fueling/update/:id',
    component: UpdateFuelingType,
    props: true,
    meta: {
      title: 'Заправки',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/fuel-types',
    component: FuelTypes,
    meta: {
      title: 'Виды топлива',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/fuel-types/create',
    component: CreateFuelType,
    meta: {
      title: 'Виды топлива',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/fuel-types/update/:id',
    component: UpdateFuelType,
    props: true,
    meta: {
      title: 'Виды топлива',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/fuel-consumptions',
    component: Consumption,
    meta: {
      title: 'Расход топлива',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/fuel-consumptions/create',
    component: CreateConsumptionType,
    meta: {
      title: 'Добавить расход',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/fuel-consumptions/update/:id',
    component: UpdateConsumptionType,
    props: true,
    meta: {
      title: 'Изменить расход',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/fuel-consumptions/car/:id',
    component: ConsumptionCar,
    props: true,
    meta: {
      title: 'Изменить расход',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/car-driver',
    component: CarDriver,
    meta: {
      title: 'Текущий водитель',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/car-driver/update/:id',
    component: UpdateCarDriver,
    props: true,
    meta: {
      title: 'Изменить текущего водителя',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/car-driver/add',
    component: CreateCarDriver,
    meta: {
      title: 'Добавить текущего водителя',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
