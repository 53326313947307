<template>
  <div class="page">
    <HeaderTitle />
    <div class="row">
      <div class="col-12">
        <label>Название</label>
        <input
          v-model="fuelType.name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <hr class="mb-4">
    <button class="btn btn-primary mr-2" @click="createFuelType()">Добавить</button>
    <router-link to="/fuel-types" class="btn btn-secondary">Отменить</router-link>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
import FuelTypeApi from '../../api/fuelType'

export default {
  name: 'CreateFuelType',
  data () {
    return {
      isCreating: false,
      fuelType: {
        name: ''
      }
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'Топливо',
        url: '/fueling'
      }, {
        title: 'Виды топлива',
        url: '/fuel-types'
      }, {
        title: 'Добавить'
      }
    ])
  },
  methods: {
    createFuelType () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      FuelTypeApi.createItem(this.fuelType)
        .then(() => {
          this.isCreating = false
          this.$router.push('/fuel-types')
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
