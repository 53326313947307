/* eslint-disable camelcase */

import fuelType from './fuelType'
import carFueling from './carFueling'
import fuelConsumption from './fuelConsumption'
import carDriver from './carDriver'

export default {
  namespaced: true,
  modules: {
    fuelType,
    carFueling,
    fuelConsumption,
    carDriver
  }
}
