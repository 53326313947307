import APITemplate from '@/api/APITemplate'

const url = '/api/fuel-type'
const template = APITemplate(url)

export default {
  ...template,
  archivateItem (id) {
    return fetch(`${url}/archivate?id=${id}`, {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  },
  getActiveFuels () {
    return fetch(`${url}/get-active-fuels`, {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  }
}
