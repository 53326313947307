<template>
  <div class="page">
    <HeaderTitle />
    <div class="d-flex justify-content-end">
      <router-link to="/fuel-consumptions" class="btn btn-secondary mr-2">Отменить</router-link>
      <button v-if="consumption" class="btn btn-primary" @click="updateRecord">Сохранить</button>
    </div>
    <div v-if="consumption">
      <div class="row">
        <div class="col-12">
          <label>Регистрационный номер машины</label>
          <VSelect
            v-model="consumption.car_id"
            style="width: 100%"
            :options="cars"
            :reduce="option => option.id"
            label="registration_plate"
          >
            <template v-slot:option="option">
              {{ option.brand }} {{ option.model }}
              <sub>рег. номер: {{ option.registration_plate }}</sub>
            </template>
          </VSelect>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Тип топлива</label>
          <VSelect
            v-model="consumption.fuel_type_id"
            style="width: 100%"
            :options="fuels"
            :reduce="option => option.id"
            label="name"
          >
            <template v-slot:option="option">{{ option.name }}</template>
          </VSelect>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Расход, на 100км</label>
          <input
            v-model="consumption.consumption"
            type="number"
            class="form-control"
            placeholder
            required
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Процент использования (сумма процентов на всех видах не должна превышать 100%)</label>
          <input
            v-model="consumption.percentage_of_use"
            type="number"
            class="form-control"
            placeholder
            required
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Дата</label>
          <input
            v-model="date"
            type="datetime-local"
            class="form-control"
            placeholder
            required
          >
        </div>
      </div>
      <hr class="mb-4">
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { mapState, mapActions } from 'vuex'
import FuelConsumptionsApi from '../../api/fuelConsumptions'

export default {
  name: 'UpdateConsumptionType',
  props: {
    id: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      isUpdating: false,
      consumption: null
    }
  },
  computed: {
    ...mapState('fuel/fuelConsumption', {
      consumptionState (state) {
        return state.items.find(e => e.id == this.id)
      },
      isLoading: state => state.isLoading
    }),
    ...mapState('taxi/cars', {
      cars: state => state.items
    }),
    ...mapState('fuel/fuelType', {
      fuelTypes: state => state.items.filter(e => !e.archived_at)
    }),
    date: {
      get () {
        return dayjs(this.consumption.date).format('YYYY-MM-DDTHH:mm')
      },
      set (dateTime) {
        this.consumption.date = dayjs(dateTime, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm:ssZZ')
      }
    },
    fuels: {
      get () {
        if (!this.fuelsCopy) {
          return JSON.parse(JSON.stringify(this.fuelTypes))
        }
        return this.fuelsCopy
      },
      set (value) {
        // eslint-disable-next-line no-unused-vars
        value.forEach((v, i) => {
          this.updateItem({ ...v })
        })
        this.fuelsCopy = value
      }
    }
  },
  watch: {
    'consumption.car_id': {
      handler (carId) {
        const car = this.cars.find(car => car.id === carId)
        if (!car) {
          return false
        }

        this.setTitle([
          {
            title: 'Расход',
            url: '/fuel-consumptions'
          },
          {
            title: 'Изменить'
          },
          {
            title: car.brand + ' ' + car.model + ' ' + car.registration_plate
          }
        ])
      },
      immediate: true
    }
  },
  beforeMount () {
    this.loadCars()
    this.loadFuels()
    this.loadConsumptions()
      .then(() => {
        this.consumption = JSON.parse(JSON.stringify(this.consumptionState ? this.consumptionState : null))
      })
  },
  methods: {
    updateRecord () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      FuelConsumptionsApi.updateItem(this.consumption)
        .then(() => {
          this.isUpdating = false
          this.$router.push('/fuel-consumptions')
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi/cars', {
      loadCars: 'loadItems'
    }),
    ...mapActions('fuel/fuelType', {
      loadFuels: 'loadItems'
    }),
    ...mapActions('fuel/fuelConsumption', {
      loadConsumptions: 'loadItems'
    }),
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
