<template>
  <div class="page page-fuel-type">
    <div class="d-flex justify-content-end">
      <router-link to="/fuel-types/create" class="btn btn-primary">
        <font-awesome-icon class="text-white" icon="plus" />
        Добавить
      </router-link>
    </div>
    <br>
    <!-- TODO: <button>ДОБАВИТЬ ГРУППУ</button> -->
    <div class="draggable-cards-wrapper">
      <DraggableTree
        class="tree4"
        :data="fuelTypes"
        :draggable="true"
        :indent="30"
        :space="0"
        @change="changeItems"
      >
        <div slot-scope="{data}">
          <template v-if="!data.isDragPlaceHolder">
            <div class="row text-dark py-3 list-group-item">
              <div class="col">
                <font-awesome-icon
                  icon="equals"
                  class="mx-2"
                  :style="{
                    color: 'gray',
                    'margin-left': 'auto',
                    cursor: 'pointer'
                  }"
                  @click="store.toggleOpen(data)"
                />
                {{ data.name }}
                <span class="badge" style="line-height: unset">
                  {{ data.archived_at }}
                </span>
              </div>
              <div class="col-auto right-icons">
                <font-awesome-icon
                  icon="pen"
                  :style="{
                    color: 'gray',
                    'margin-left': 'auto',
                    cursor: 'pointer'
                  }"
                  @click="$router.push(`/fuel-types/update/${data.id}`)"
                />
                <font-awesome-icon
                  icon="trash-alt"
                  :style="{
                    color: 'gray',
                    'margin-left': '1.5rem',
                    cursor: 'pointer'
                  }"
                  @click="removeItem(data)"
                />
              </div>
            </div>
          </template>
        </div>
      </DraggableTree>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FuelTypeApi from '../api/fuelType'
import Swal from 'sweetalert2'

export default {
  name: 'FuelTypes',
  data () {
    return {
      editable: true,
      fuelTypesCopy: null
    }
  },
  computed: {
    ...mapState('fuel/fuelType', {
      allFuelTypes: state => state.items.filter(e => !e.archived_at),
      isLoading: state => state.isLoading
    }),
    fuelTypesList () {
      if (!this.fuelTypesCopy) {
        return JSON.parse(JSON.stringify(this.allFuelTypes))
      }
      return this.fuelTypesCopy
    },
    fuelTypes: {
      get () {
        this.fuelTypesList.forEach(v => v.droppable = false)
        return JSON.parse(JSON.stringify(this.fuelTypesList)).sort((a, b) => (a.sort > b.sort) ? 1 : -1)
      },
      set (value) {
        value.forEach(v => this.updateItem(v))
        this.fuelTypesCopy = value
      }
    }
  },
  mounted () {
    this.loadItems()
    this.setTitle([
      {
        title: 'Виды топлива'
      }
    ])
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('fuel/fuelType', [
      'loadItems',
      'updateItem',
      'setSorts',
      'abortLoading'
    ]),
    changeItems (node) {
      node.parent.children.forEach((v, i) => {
        this.updateItem({
          id: v.id,
          name: v.name,
          created_at: v.created_at,
          archived_at: v.archived_at,
          sort: i
        })
      })
    },
    removeItem (fuelType) {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите архивировать вид топлива?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Архивировать'
      }).then(event => {
        if (event.value) {
          FuelTypeApi.archivateItem(fuelType.id)
            .then(() => {
              this.loadItems()
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .list-group-item {
    display: flex;
  }
  .list-group-item i {
    cursor: pointer;
  }
  .page-fuel-type {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
  .draggable-cards-wrapper {
    height: 100%;
    flex-direction: column;
    flex-flow: nowrap;
  }
</style>
