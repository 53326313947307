<template>
  <div class="page">
    <table class="table table-hover w-100 mt-3 d-none d-md-table">
      <TableSort class="sticky-1" :sorts="sorts" @sortsUpdate="loadWithSort" />
      <tbody>
        <tr v-for="car of cars" :key="car.id" @click="$router.push(`/fuel-consumptions/car/${car.id}`)">
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.brand }} {{ car.model }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.registration_plate }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ getConsumption(car.id) }}</TextHighlight>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-for="car of cars" :key="car.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
      <table v-if="cars.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Марка автомобиля</td>
            <td style="border-top: none">
              <TextHighlight class="highlighter" :queries="searchText">{{ car.brand }} {{ car.model }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Модель автомобиля</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ car.registration_plate }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Рег. номер</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ getConsumption(car.id) }}</TextHighlight>
            </td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="`/fuel-consumptions/car/${car.id}`"
        class="btn btn-primary btn-md create-serve ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FuelConsumptionsApi from '../api/fuelConsumptions'

export default {
  name: 'Consumption',
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'Марка, модель',
          storeName: 'servecar-brand',
          values: ['', 'brand', '-brand'],
          order: 0
        }, {
          name: 'Рег. номер',
          storeName: 'servecar-registration_plate',
          values: ['', 'registration_plate', '-registration_plate'],
          order: 1
        }, {
          name: 'Текущий расход',
          storeName: '',
          values: ['', '', ''],
          order: 2,
          disabled: true
        }
      ],
      consumptions: []
    }
  },
  computed: {
    ...mapState('fuel/fuelConsumption', {
      consumptionList: state => state.items,
      searchText: state => state.searchText,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading
    }),
    ...mapState('taxi/cars', {
      cars: state => state.items,
      searchText: state => state.searchText,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading
    }),
    ...mapState('fuel/fuelType', {
      fuelTypes: state => state.items.filter(e => !e.archived_at)
    }),
    getConsumption () {
      return (carId) => {
        if (this.consumptions.length) {
          const consumptions = this.consumptions.filter(consumption => consumption.car_id === carId.toString())
          return consumptions
            .map(consumption => {
              const fuelType = this.getFuelTypeName(consumption.fuel_type_id) ?? 'Тип ' + consumption.fuel_type_id
              return fuelType + ': ' + consumption.consumption
            })
            .join(', ')
        }
        return 'Отсутствует'
      }
    },
    getFuelTypeName () {
      return (fuelTypeId) => {
        return this.fuelTypes.find(fuelType => fuelType.id.toString() === fuelTypeId)?.name ?? null
      }
    }
  },
  watch: {
    page () {
      this.loadItems({ page: Number(this.page) })
    }
  },
  beforeMount () {
    this.loadFuels()
    FuelConsumptionsApi.getActualConsumptions()
      .then(consumptions => {
        this.consumptions = consumptions
      })
  },
  mounted () {
    this.setTitle([
      {
        title: 'Расход'
      }
    ])
    this.loadCars({ page: Number(this.page) })
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('fuel/fuelConsumption', [
      'loadItemsWith',
      'abortLoading',
      'removeItem',
      'setSearch',
      'loadItems',
      'setSearchText',
      'setSorts'
    ]),
    ...mapActions('fuel/fuelType', {
      loadFuels: 'loadItems'
    }),
    ...mapActions('taxi/cars', { loadCars: 'loadItems' }),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    searchConsumption (e) {
      this.setSearchText(e.target.value)
      this.setSearch({ key: 'tracking_device.imei', value: e.target.value })
      this.setSearch({ key: 'brand', value: e.target.value })
      this.setSearch({ key: 'model', value: e.target.value })
      this.setSearch({ key: 'registration_plate', value: e.target.value })
      this.loadItems({ page: Number(this.page) })
    },
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page) })
    }
  }
}
</script>

<style scoped>
  .highlighter >>> .text__highlight {
    border-radius: 0px;
    padding: 0.2em 0 0.2em 0;
    background: #fff2ae;
  }
</style>
