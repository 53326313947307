<template>
  <div class="page">
    <HeaderTitle />
    <div class="d-flex justify-content-end">
      <router-link to="/fueling" class="btn btn-secondary mr-2">Отменить</router-link>
      <button v-if="fueling" class="btn btn-primary mr-2" @click="updateRecord">Сохранить</button>
      <button v-if="fueling && canUseDelete" class="btn btn-danger" @click="deleteRecord">Удалить</button>
    </div>
    <div v-if="fueling">
      <div class="row">
        <div class="col-12">
          <label>Регистрационный номер машины</label>
          <VSelect
            v-model="fueling.car_id"
            style="width: 100%"
            :options="cars"
            :reduce="option => option.id"
            label="registration_plate"
          >
            <template v-slot:option="option">
              {{ option.brand }} {{ option.model }}
              <sub>рег. номер: {{ option.registration_plate }}</sub>
            </template>
          </VSelect>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Тип топлива</label>
          <VSelect
            v-model="fueling.fuel_type_id"
            style="width: 100%"
            :options="fuels"
            :reduce="option => option.id"
            label="name"
          >
            <template v-slot:option="option">{{ option.name }}</template>
          </VSelect>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Отстаток, л</label>
          <input
            v-model="fueling.remaining"
            type="number"
            class="form-control"
            placeholder
            required
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Заполнено, л</label>
          <input
            v-model="fueling.fueled"
            type="number"
            class="form-control"
            placeholder
            required
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Расчет</label>
          <VSelect
            v-model="fueling.cash"
            class="w-100"
            :options="payments"
            :reduce="payment => payment.value"
            label="name"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Дата заправки</label>
          <input
            v-model="date"
            type="datetime-local"
            class="form-control"
            placeholder
            required
          >
        </div>
      </div>
      <hr class="mb-4">
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { mapState, mapActions } from 'vuex'
import CarFuelingApi from '../../api/carFueling'
import userApi from '@/api/user'

export default {
  name: 'UpdateFueling',
  props: {
    id: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      canUseDelete: false,
      isUpdating: false,
      fueling: null,
      payments: [
        {
          name: 'Наличный расчет',
          value: 1
        }, {
          name: 'Безналичный расчет',
          value: 0
        }
      ]
    }
  },
  computed: {
    ...mapState('fuel/carFueling', {
      fuelingState (state) {
        return state.items.find(e => e.id == this.id)
      },
      isLoading: state => state.isLoading
    }),
    ...mapState('taxi/cars', {
      cars: state => state.items
    }),
    ...mapState('fuel/fuelType', {
      fuelTypes: state => state.items.filter(e => !e.archived_at)
    }),
    date: {
      get () {
        return dayjs(this.fueling.date).format('YYYY-MM-DDTHH:mm')
      },
      set (dateTime) {
        this.fueling.date = dayjs(dateTime, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm:ssZZ')
      }
    },
    fuels: {
      get () {
        if (!this.fuelsCopy) {
          return JSON.parse(JSON.stringify(this.fuelTypes))
        }
        return this.fuelsCopy
      },
      set (value) {
        // eslint-disable-next-line no-unused-vars
        value.forEach((v, i) => {
          this.updateItem({ ...v })
        })
        this.fuelsCopy = value
      }
    }
  },
  beforeMount () {
    this.loadCars()
    this.loadFuels()
    if (!this.fuelingState) {
      this.loadFueling()
        .then(() => {
          this.fueling = JSON.parse(JSON.stringify(this.fuelingState ? this.fuelingState : null))
        })
    }
    this.fueling = this.fuelingState


    userApi.hasPermission('/car-fueling/delete')
      .then(permissions => {
        this.canUseDelete = permissions
      })
      .catch(() => {
        this.canUseDelete = false
      })
  },
  mounted () {
    this.setTitle([
      {
        title: 'Заправки',
        url: '/fueling'
      }, {
        title: 'Изменить'
      }
    ])
  },
  methods: {
    deleteRecord () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить заправку?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          CarFuelingApi.removeItem(this.fueling.id)
            .then(() => {
              this.$router.push('/fueling')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    updateRecord () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      CarFuelingApi.updateItem(this.fueling)
        .then(() => {
          this.isUpdating = false
          this.$router.push('/fueling')
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi/cars', {
      loadCars: 'loadItems'
    }),
    ...mapActions('fuel/fuelType', {
      loadFuels: 'loadItems'
    }),
    ...mapActions('fuel/carFueling', {
      loadFueling: 'loadItems'
    }),
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
