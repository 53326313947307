<template>
  <div class="page">
    <HeaderTitle />
    <div class="row">
      <div class="col-12">
        <label>Регистрационный номер машины</label>
        <VSelect
          v-model="carDriver.car_id"
          style="width: 100%"
          :options="cars"
          :reduce="option => option.id"
          label="registration_plate"
        >
          <template v-slot:option="option">
            {{ option.brand }} {{ option.model }}
            <sub>рег. номер: {{ option.registration_plate }}</sub>
          </template>
        </VSelect>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Водитель</label>
        <VSelect
          v-model="carDriver.driver_id"
          style="width: 100%"
          :options="drivers"
          :reduce="option => option.id"
          label="last_name"
        >
          <template v-slot:option="option">{{ option.last_name }} {{ option.first_name }} {{ option.middle_name }}</template>
        </VSelect>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Дата передачи</label>
        <input
          v-model="transferDatetime"
          type="date"
          class="form-control"
          min="2010-06-01T08:30"
          max="2050-06-30T16:30"
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Дата возврата</label>
        <input
          v-model="receivingDatetime"
          type="date"
          class="form-control"
          min="2010-06-01T08:30"
          max="2050-06-30T16:30"
          required
        >
      </div>
    </div>
    <hr class="mb-4">
    <div class="row">
      <div class="col-12">
        <label>Пробег на момент передачи</label>
        <input
          v-model="odometerCorrection.actual_odometer"
          type="text"
          class="form-control"
          placeholder
        >
      </div>
    </div>
    <hr class="mb-4">
    <button class="btn btn-primary mr-2" @click="createCarDriver()">Добавить</button>
    <router-link to="/car-driver" class="btn btn-secondary">Отменить</router-link>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import { djsMixin } from '@/mixins/djsMixin'
import CarDriverApi from '../../api/carDriver'
import userApi from '@/api/user'
import odometerCorrectionApi from '@/api/odometerCorrection'
import dayjs from 'dayjs'

export default {
  name: 'CreateFueling',
  mixins: [djsMixin],
  data () {
    return {
      isCreating: false,
      carDriver: {
        car_id: 0,
        driver_id: 0,
        transfer_datetime: null,
        receiving_datetime: null,
      },
      odometerCorrection: {
        tracking_device_id: null,
        actual_odometer: null,
        timestamp: this.now(),
        device_odometer: null,
        car_id: null,
        author_id: null
      },
    }
  },
  computed: {
    ...mapState('taxi/cars', {
      cars: state => state.items
    }),
    ...mapState('taxi/drivers', {
      drivers: state => state.items
    }),
    ...mapState('taxi/trackDevice', {
      devices: state => state.items
    }),
    receivingDatetime: {
      get () {
        return dayjs(this.carDriver.receiving_datetime).format('YYYY-MM-DD')
      },
      set (dateTime) {
        this.carDriver.receiving_datetime = dayjs(dateTime, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss')
      }
    },
    transferDatetime: {
      get () {
        return dayjs(this.carDriver.transfer_datetime).format('YYYY-MM-DD')
      },
      set (dateTime) {
        this.carDriver.transfer_datetime = dayjs(dateTime, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss')
      }
    },
  },
  beforeMount () {
    this.loadCars()
    this.loadDrivers()
    this.loadDeviceItems()
  },
  mounted () {
    this.setTitle([
      {
        title: 'Текущий водитель',
        url: '/car-driver'
      }, {
        title: 'Добавить'
      }
    ])
  },
  methods: {
    createOdometerCorrection () {
      if (!this.isCreating && this.odometerCorrection.actual_odometer) {
        let car = this.cars.find(car => car.id === this.carDriver.car_id)
        if (!car) {
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            text: 'Не найдена машина'
          })
          this.$router.push('/car-driver')
        }
        let trackingDevice = this.devices.find(device => device.id === car.tracking_device_id)
        if (!trackingDevice) {
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            text: 'Не найдено устройство геопозиционирования'
          })
          this.$router.push('/car-driver')
        }
        this.isCreating = true
        userApi
          .getCurrentUser()
          .then(user => {
            this.isCreating = true
            this.odometerCorrection.author_id = user.id
            this.odometerCorrection.car_id = car.id
            this.odometerCorrection.tracking_device_id = trackingDevice.id
            odometerCorrectionApi.createItem({
              ...this.odometerCorrection,
              timestamp: this.toUTCtranslate(this.carDriver.transfer_datetime)
            })
          })
          .catch(err => {
            console.error(err)
            this.isCreating = false
            Swal.fire({
              icon: 'error',
              title: 'Ошибка',
              html: err.message
            })
          })
      }
      this.$router.push('/car-driver')
    },
    createCarDriver () {
      if (this.isCreating) {
        return
      }
      CarDriverApi.createItem(this.carDriver)
        .then(() => {
          this.isCreating = false
          this.createOdometerCorrection()
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('taxi/cars', {
      loadCars: 'loadItems'
    }),
    ...mapActions('taxi/drivers', {
      loadDrivers: 'loadItems'
    }),
    ...mapActions('taxi/trackDevice', {
      loadDeviceItems: 'loadItems'
    }),
  }
}
</script>
